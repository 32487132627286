import { createContext, useContext, useEffect, useState } from 'react';
import { useIdleTimer } from 'react-idle-timer';
import { notification } from 'antd';
import { useRouter } from 'next/router';

import { firebase } from '@app/services/firebase';
import { isTicketAgent, queryString, isValidateEmail } from '@app/utils';
import { FirebaseErrorResolver, UserType } from '@app/types';
import { getUser, updateUser } from '@app/lib/firestore';
import { AllowedType } from '@app/components/layouts/layout';

type AuthProviderPropType = {
  children: React.ReactNode;
};

type VerificationId = string;

type AuthProviderValueType = {
  isReady: boolean;
  user: UserType | null;
  signIn: (username: string, password: string) => Promise<UserType | boolean>;
  signOut: () => Promise<void>;
  sendPasswordResetEmail: (email: string) => Promise<boolean>;
  confirmPasswordReset: (password: string, code: string) => Promise<boolean>;
  send2FACode: (
    phoneInfoOpts: firebase.auth.PhoneInfoOptions,
    recaptchaVerifier: firebase.auth.RecaptchaVerifier,
  ) => Promise<VerificationId>;
  resolveSignIn: (
    verifyCode: string,
    verifyId: string,
    resolver: FirebaseErrorResolver,
  ) => Promise<UserType | boolean | void>;
  checkAuthorization: (allowedRoles: AllowedType[]) => boolean;
  reloadUser: () => Promise<void>;
};

const formatUser = (rawUser: UserType, authUser: firebase.User): UserType => ({
  uid: rawUser.uid,
  authUser,
  name: rawUser.name,
  email: rawUser.email,
  roles: rawUser.roles,
  assignedDisplay: rawUser.assignedDisplay,
  lastUpdatedAt: firebase.firestore.FieldValue.serverTimestamp() as firebase.firestore.Timestamp,
});

const AuthContext = createContext<AuthProviderValueType>({} as AuthProviderValueType);

function useProviderAuth() {
  let phoneAuthProvider: firebase.auth.PhoneAuthProvider;
  const [user, setUser] = useState<UserType | null>(null);
  const [isReady, setIsReady] = useState(false);
  const router = useRouter();
  useIdleTimer({
    timeout: parseInt(process.env.NEXT_PUBLIC_IDLE_TIMEOUT || '600000', 10),
    onIdle: () => {
      // logout
      if (user) {
        firebase
          .auth()
          .signOut()
          .then(() => {
            setUser(null);
            router.push({ pathname: '/login', query: { idleTimeout: true } });
          });
      }
    },
  });
  // console.log(user);

  const getEmail = (username: string): string => {
    return isTicketAgent(username)
      ? `${username}.ta@${process.env.NEXT_PUBLIC_EMAIL_DOMAIN || 'kandycitycentre.lk'}`
      : username;
  };

  const handleUser = async (rawUser: firebase.User | null): Promise<UserType | boolean> => {
    let returnValue;
    if (rawUser) {
      try {
        const doc = await getUser(rawUser.uid);
        const currentUser: UserType = formatUser(doc.data() as UserType, rawUser);
        const { authUser, ...restUser } = currentUser;
        await updateUser(currentUser.uid, restUser);
        returnValue = currentUser;
        setUser(currentUser);
      } catch (error) {
        returnValue = false;
        notification.error({
          message: 'Login failed.',
          description: error.message,
          duration: 5,
        });
      }
    } else {
      setUser(null);
      returnValue = false;
    }
    return returnValue;
  };

  const signIn = async (username: string, password: string) => {
    let email = username;
    if (!isValidateEmail(email)) {
      email = getEmail(username);
    }

    return firebase
      .auth()
      .setPersistence(firebase.auth.Auth.Persistence.LOCAL)
      .then(() => {
        return firebase
          .auth()
          .signInWithEmailAndPassword(email, password)
          .then((response) => handleUser(response.user));
      });
  };

  // const signUp = (username: string, password: string) => {
  //   let email = username;
  //   if (!isValidateEmail(email)) {
  //     email = getEmail(username);
  //   }
  //   return firebase
  //     .auth()
  //     .createUserWithEmailAndPassword(email, password)
  //     .then((response) => handleUser(response.user));
  // };

  const signOut = () => {
    return firebase
      .auth()
      .signOut()
      .then(() => {
        handleUser(null);
      });
  };

  const sendPasswordResetEmail = (email: string) => {
    return firebase
      .auth()
      .sendPasswordResetEmail(email)
      .then(() => {
        return true;
      });
  };

  const confirmPasswordReset = (password: string, code: string) => {
    const resetCode = code || (queryString().oobCode as string);

    return firebase
      .auth()
      .confirmPasswordReset(resetCode, password)
      .then(() => {
        return true;
      });
  };

  const send2FACode = (
    phoneInfoOpts: firebase.auth.PhoneInfoOptions,
    recaptchaVerifier: firebase.auth.RecaptchaVerifier,
  ): Promise<string> => {
    phoneAuthProvider = new firebase.auth.PhoneAuthProvider();
    return phoneAuthProvider
      .verifyPhoneNumber(phoneInfoOpts, recaptchaVerifier)
      .then((verificationId) => {
        return verificationId;
      });
  };

  const resolveSignIn = (
    verifyCode: string,
    verifyId: string,
    resolver: FirebaseErrorResolver,
  ): Promise<UserType | boolean | void> => {
    const cred = firebase.auth.PhoneAuthProvider.credential(verifyId, verifyCode);
    const multiFactorAssertion = firebase.auth.PhoneMultiFactorGenerator.assertion(cred);

    return resolver
      .resolveSignIn(multiFactorAssertion)
      .then((userCredential: firebase.auth.UserCredential) => {
        let returnUser;
        if (userCredential.user) {
          returnUser = handleUser(userCredential.user);
        } else {
          returnUser = handleUser(null);
        }
        return returnUser;
      })
      .catch((error: firebase.FirebaseError) => {
        notification.error({
          message: error.message,
          duration: 5,
        });
      });
  };

  const checkAuthorization = (allowedRoles: AllowedType[]): boolean => {
    let returnPermission = false;
    if (!user) {
      returnPermission = false;
    } else {
      allowedRoles.forEach((role) => {
        if (user.roles[role]) {
          returnPermission = true;
        }
      });
    }

    return returnPermission;
  };

  const reloadUser = async () => {
    const { currentUser } = firebase.auth();
    if (currentUser) {
      await currentUser.reload();
      const doc = await getUser(currentUser.uid);
      const currentUserData = formatUser(doc.data() as UserType, currentUser);
      setUser(currentUserData);
    }
  };

  useEffect(() => {
    const unsubscribe = firebase.auth().onAuthStateChanged(async (changedUser) => {
      if (changedUser) {
        const doc = await getUser(changedUser.uid);
        const changedUserformated = formatUser(doc.data() as UserType, changedUser);
        // updateUser(changedUserformated.uid, changedUserformated).then(() => {
        //   setUser(changedUserformated);
        // });
        setUser(changedUserformated);
      } else {
        setUser(null);
      }
      if (!isReady) {
        setIsReady(true);
      }
    });

    return () => unsubscribe();
  }, [isReady]);

  return {
    isReady,
    user,
    signIn,
    signOut,
    sendPasswordResetEmail,
    confirmPasswordReset,
    send2FACode,
    resolveSignIn,
    checkAuthorization,
    reloadUser,
  };
}

export const AuthProvider = ({ children }: AuthProviderPropType): JSX.Element => {
  const auth = useProviderAuth();
  return <AuthContext.Provider value={auth}>{children}</AuthContext.Provider>;
};

export const useAuth = (): AuthProviderValueType => {
  return useContext(AuthContext);
};
